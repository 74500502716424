import React from "react";
import HomePage from "../components/layout/Home";
import Faq from "../components/home/faq-takeover";

export default props => {
  return (
    <HomePage>
      <Faq />
    </HomePage>
  );
};
